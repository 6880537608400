<template>
  <ion-page >
    <ion-content :style="' --background: url(assets/img/login_bg.webp)'" class="ion-padding-top ion-margin-top ion-margin">

      <div>
      <ion-row class="center" style=" background-color: rgba(255, 255, 255, 0.5); /* Biały z 50% przezroczystości */
    color: black; /* Kolor tekstu */
    padding: 10px; /* Odstęp wewnątrz elementu */">
        <ion-col class="ion-text-center">
          <h1 class="text-uppercase text-white " style="color:#fff;">{{$t('Login')}}</h1>
        </ion-col>
      </ion-row>
      <ion-row class="center" style=" background-color: rgba(255, 255, 255, 0.5); /* Biały z 50% przezroczystości */
    color: black; /* Kolor tekstu */
    padding: 5px; /* Odstęp wewnątrz elementu */">
        <ion-col>
          <ion-item>
            <ion-label position="stacked">{{$t('email')}}</ion-label>
            <ion-input v-model="username"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="center" style=" background-color: rgba(255, 255, 255, 0.5); /* Biały z 50% przezroczystości */
    color: black; /* Kolor tekstu */
    padding: 10px; /* Odstęp wewnątrz elementu */">
        <ion-col>
          <ion-item>
            <ion-label position="stacked">{{$t('password')}}</ion-label>
            <ion-input v-model="password" type="password"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row >
      <ion-row class="center" style=" background-color: rgba(255, 255, 255, 0.5); /* Biały z 50% przezroczystości */
    color: black; /* Kolor tekstu */
    padding: 10px; /* Odstęp wewnątrz elementu */">
        <ion-col>
          <ion-button expand="full" @click="login">{{$t('login')}}</ion-button>
        </ion-col>
      </ion-row>
      </div>
    </ion-content>
    <ion-loading :is-open="isLoading" :message="$t('logging_in')"></ion-loading>
  </ion-page>
</template>

<script>
import {IonContent, IonPage, IonItem, IonLabel, IonInput, IonButton, IonRow, IonCol, IonLoading} from '@ionic/vue';
import axios from 'axios'
import {Storage} from "@ionic/storage";
import { Filesystem, Directory } from '@capacitor/filesystem';
import router from '../router';
import { applyTheme } from '@/services/theme';

export default {
  name: 'LoginForm',
  components: {
    IonContent, IonPage, IonItem, IonLabel, IonInput, IonButton, IonRow, IonCol, IonLoading},
  data() {
    return {
      username: '',
      password: '',
      isLoading: false,
      user: null
    };
  },
  methods: {
    async login() {
      const storage = new Storage();
      await storage.create();
      try {
        this.isLoading = true
        await axios.post(process.env.VUE_APP_API_URL + '/login', {
          email: this.username,
          password: this.password
        }).then(response => {
          this.isLoading = false;
           storage.set('user', response.data.data);
          this.getConfig();
        });
        // Przechowaj token w pamięci lokalnej lub w plikach cookie
      } catch (error) {
        this.isLoading = false
        alert(this.$t('error_login'));
      }
    },
    async getConfig()
    {
      const storage = new Storage();
      await storage.create();
      const user = storage.get('user').then(res => {
        localStorage.setItem('token', res.token);
        this.isLoading = true
        axios.get(process.env.VUE_APP_API_URL + '/card-config',{
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }}).then(response => {
          this.isLoading = false;
          console.log(response.data);
          storage.set('config', response.data);
          const bg = response.data.data.bg_img;
          applyTheme(response.data);
          this.saveImg(bg);
          router.push('/tabs');
        });
      });
    },
    async saveImg(base64Image) {
      try {
        // Konwersja Base64 na Blob
        const imageBlob = this.dataURItoBlob(base64Image);

        // Zapisywanie Blob jako pliku
        await Filesystem.writeFile({
          path: 'public/assets/bg.png',
          data: imageBlob,
          directory: Directory.Documents,
          recursive: true
        });

        console.log('Obrazek zapisany pomyślnie');
      } catch (e) {
        console.error('Błąd zapisu obrazka:', e);
      }
    },
    dataURItoBlob(dataURI) {
      console.log(dataURI);
      const byteString = window.atob(dataURI.split(',')[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }

      return new Blob([int8Array], { type: 'image/png' });
    }
  }
};
</script>
<style>
.center {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 50%;
  margin:auto;
}
ion-img img {
  border-radius: 20%;
}
</style>
