<template>
  <ion-page>
    <ion-loading :is-open="isLoading" :message="$t('logging_in')"></ion-loading>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonLoading
} from '@ionic/vue';
import 'swiper/css';
import axios from 'axios'
import {Storage} from "@ionic/storage";
import {applyTheme} from "@/services/theme";
import router from "@/router";
export default {
  name: 'WineIndex',
  components: {
    IonPage,
    IonLoading
  },
  data() {
    return {
      isLoading: false
    }
  },
  async mounted() {
    this.isLoading = true;
    this.restaurantHash = this.$route.params.hash;
    if (this.restaurantHash) {
      const storage = new Storage();
      await storage.create();
      axios.post(process.env.VUE_APP_API_URL + '/cart/login_hash/' + this.restaurantHash).then(res => {
        storage.set('user', res.data.user);
        storage.set('table', res.data.table);
          localStorage.setItem('token', res.data.user.token);
          axios.get(process.env.VUE_APP_API_URL + `/card-config?q=${this.restaurantHash}`,{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }}).then(response => {
            storage.set('config', response.data);
            console.log(response.data);
            const bg = response.data.data.bg_img;
            applyTheme(response.data);
            this.isLoading = false;
            router.push('/tabs');
          });
      }).catch(err => {
        console.error(err);
        alert('Przykro nam, kod QR jest nieprawidlowy. Prosze skontktowac sie z obslugsa')
      })
    }
  }
}
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  margin: 0;
}

.fullscreen {
  /* --width: 100% !important;
  --height: 100% !important; */
}

#container a {
  text-decoration: none;
}
.fixed-button {
  position: fixed;
  right: 20px;      /* Odległość od prawej strony */
  bottom: 20px;     /* Odległość od dolnej strony (możesz to dostosować według potrzeb) */
  z-index: 1000;    /* Upewnij się, że przycisk będzie na wierzchu innych elementów */
}


</style>
