<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar>
        <ion-tab-button :style="{ color: tab === 'home' ? user?.bottom_menu_icon_active_color : user?.bottom_menu_icon_color }" tab="home" href="/home" @click="onHomeClick()">
          <ion-icon :style="{ color: tab === 'home' ? user?.bottom_menu_icon_active_color : user?.bottom_menu_icon_color }" :icon="restaurant" />
          <ion-label :style="{ color: tab === 'home' ? user?.bottom_menu_icon_active_color : user?.bottom_menu_icon_color }">Karta</ion-label>
        </ion-tab-button>

        <ion-tab-button @click="onTabClick('cart')" :style="{ color: tab === 'cart' ? user?.bottom_menu_icon_active_color : user?.bottom_menu_icon_color }" tab="cart" href="/cart">
          <ion-icon :style="{ color: tab === 'cart' ? user?.bottom_menu_icon_active_color : user?.bottom_menu_icon_color }" :icon="cartIcon" />
          <ion-label :style="{ color: tab === 'cart' ? user?.bottom_menu_icon_active_color : user?.bottom_menu_icon_color }">Koszyk</ion-label>
          <ion-badge style="background: #eb445a; color: #fff;" v-if="cart.items?.length" color="danger">{{ cart.items?.length }}</ion-badge>
        </ion-tab-button>

        <ion-tab-button @click="onTabClick('promotion')" :style="{ color: tab === 'promotion' ? user?.bottom_menu_icon_active_color : user?.bottom_menu_icon_color }" v-show="user?.promotion_enabled" tab="promotion" href="/promotion">
          <ion-icon :style="{ color: tab === 'promotion' ? user?.bottom_menu_icon_active_color : user?.bottom_menu_icon_color }" :icon="gift" />
          <ion-label :style="{ color: tab === 'promotion' ? user?.bottom_menu_icon_active_color : user?.bottom_menu_icon_color }">Promocja</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>

  import {
    IonPage,
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonLabel,
    IonIcon,
  } from "@ionic/vue";
  import {restaurant, cart as cartIcon, gift} from "ionicons/icons";
  import {cart, getCartItems} from '@/services/cart';
  import {applyTheme} from '@/services/theme';
  import {Storage} from "@ionic/storage";
  import router from "@/router";

  export default {
    components: {
      IonPage,
      IonTabs,
      IonRouterOutlet,
      IonTabBar,
      IonTabButton,
      IonLabel,
      IonIcon,
    },
    data() {
      return {
        restaurant,
        cartIcon,
        gift,
        cart,
        user: null,
        tab: 'home'
      };
    },
    methods: {
      onHomeClick() {
        this.tab = 'home';
        setTimeout(() => {
          router.go(0);
        }, 80);
      },
      onTabClick(selectedTab) {
        this.tab = selectedTab;
      },
    },
    async mounted() {
      const storage = new Storage();
      await storage.create();
      await storage.get('config').then(res => {
        if (res && res.data) {
          this.user = res.data;
          console.log(this.user);
          applyTheme(res.data);
        }
      });
    }
  };
</script>
<style scoped>
  .ios .tab-selected {
    color: rgb(0, 0, 0) !important;
  }
</style>
