import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import LoginForm from '../views/Login.vue';
import Restaurant from '../views/Restaurant.vue';
import Order from '../views/Order.vue';
import { Storage } from '@ionic/storage';
import TabsPage from '@/views/TabsPage.vue';
import CartPage from '@/views/CartPage.vue';
import PromotionPage from '@/views/PromotionPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginForm
  },
  {
    path: '/tabs',
    name: 'TabsPage',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/home',
      },
      {
        path: '/home',
        name: 'Home',
        component: HomePage
      },
      {
        path: '/cart',
        name: 'Cart',
        component: CartPage
      },
      {
        path: '/promotion',
        name: 'Promotion',
        component: PromotionPage
      },
    ]
  },
  {
    path: '/restaurant/:hash',
    name: 'Restaurant',
    component: Restaurant
  },
  {
    path: '/restaurant/order/:hash',
    name: 'Order',
    component: Order
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const storage = new Storage();
await storage.create();

router.beforeEach(async (to, from, next) => {
 // const userToken = await storage.get('user');
  const token = localStorage.getItem('token');


  // if ((!userToken || !token) && to.path !== '/login') {
     // If user-token is not found and the user is not on the login page, redirect to login
   //  next('/login');
 //  } else {
    // Else, proceed as normal
    next();
 // }
});

export default router
