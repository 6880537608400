export function applyTheme(data) {
    const el = document.querySelector(':root');
    el.style.setProperty('--ion-color-primary', data.primary_color);
    el.style.setProperty('--ion-text-color', data.primary_color);
    el.style.setProperty('--ion-color-primary-rgb', data.primary_color_rgb);
    el.style.setProperty('--ion-color-primary-contrast', data.primary_contrast);
    el.style.setProperty('--ion-color-primary-contrast-rgb', data.primary_contrast_rgb);
    el.style.setProperty('--ion-color-primary-shade', data.primary_shade);
    el.style.setProperty('--ion-color-primary-tint', data.primary_tint);
    el.style.setProperty('--background', data.bg);
    el.style.setProperty('--placeholder-color', data.primary_color);
    el.style.setProperty('--ion-content-background', data.bg);
    el.style.setProperty('--ion-item-background', data.bg);
    el.style.setProperty('--ion-tab-bar-background', data.bottom_menu_background);
    el.style.setProperty('--ion-tab-button-color', data.bottom_menu_icon_color);
}
