<template>
  <ion-page>
    <ion-content :style="' --background: url(assets/img/login_bg.webp)'" class="ion-padding-top ion-margin-top ion-margin">

    <ion-header class="ion-no-border ion-text-center"><h1 style="color: #fff;">Szczegóły zamoówienia</h1></ion-header>
       <ion-list   v-for="item in items" :key="item.id" :inset="true" :lines="'inset'">
          <ion-item>
            <ion-label><strong>Produkt:</strong> {{item.name}}</ion-label>
          </ion-item>
          <ion-item>
            <ion-label><strong>Ilość:</strong> {{item.quantity}}</ion-label>
          </ion-item>
          <ion-item>
            <ion-label><strong>Cena całkowita:</strong> {{item.price}} PLN</ion-label>
          </ion-item>
        </ion-list>

      <ion-list>
        <ion-item>
          <ion-label><strong>Cena całkowita: </strong> {{order?.total_price}} PLN </ion-label>
        </ion-item>
        <ion-item>
          <ion-label><strong>Stolik: </strong> {{table?.name}} </ion-label>

        </ion-item>
      </ion-list>
    <ion-loading :is-open="isLoading" :message="$t('logging_in')"></ion-loading>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonLoading
} from '@ionic/vue';
import 'swiper/css';
import axios from 'axios'
import {Storage} from "@ionic/storage";
import {applyTheme} from "@/services/theme";
import router from "@/router";
export default {
  name: 'WineIndex',
  components: {
    IonPage,
    IonLoading
  },
  data() {
    return {
      isLoading: false,
      order: null,
      items: null,
      table: null
    }
  },
  async mounted() {
    this.isLoading = true;
    this.restaurantHash = this.$route.params.hash;
    if (this.restaurantHash) {
      const storage = new Storage();
      await storage.create();
      axios.get(process.env.VUE_APP_API_URL + '/cart/order_details/' + this.restaurantHash).then(res => {
        this.order = res.data.order;
        this.items = res.data.items;
        this.table = res.data.table;
        this.isLoading = false;
      }).catch(err => {
        console.error(err);
        alert('Przykro nam, kod QR jest nieprawidlowy. Prosze skontktowac sie z obslugsa')
      })
    }
  }
}
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  margin: 0;
}

.fullscreen {
  /* --width: 100% !important;
  --height: 100% !important; */
}

#container a {
  text-decoration: none;
}
.fixed-button {
  position: fixed;
  right: 20px;      /* Odległość od prawej strony */
  bottom: 20px;     /* Odległość od dolnej strony (możesz to dostosować według potrzeb) */
  z-index: 1000;    /* Upewnij się, że przycisk będzie na wierzchu innych elementów */
}

:host(.ion-color) .inner-scroll, :host(.ion-color) .inner-scroll {
  background: transparent !important;
}
</style>
