// store/cart.js
import { reactive } from 'vue';

export const cart = reactive({
    items: [], // Zmienione z null na pustą tablicę
    addProduct(product) {
        if (product) {
            if (localStorage.getItem('cart')) {
                this.items = JSON.parse(localStorage.getItem('cart'));
            }
            const existingProduct = this.items.find((item) => item.id === product.id);
            if (existingProduct) {
                existingProduct.quantity++;
            } else {
                this.items.push({...product, quantity: 1});
            }
            localStorage.setItem('cart', JSON.stringify(this.items));
        }
    },
    removeProduct(productId, force = false) {
        if (localStorage.getItem('cart')) {
            this.items = JSON.parse(localStorage.getItem('cart'));
        }
        const productIndex = this.items.findIndex((item) => item.id === productId);
        if (productIndex !== -1) {
            if (this.items[productIndex].quantity > 1) {
                if (force) {
                    this.items.splice(productIndex, 1);
                } else {
                    this.items[productIndex].quantity--;
                }
            } else {
                this.items.splice(productIndex, 1);
            }
            localStorage.setItem('cart', JSON.stringify(this.items));
        }
    },
    get total() {
        if (localStorage.getItem('cart')) {
            this.items = JSON.parse(localStorage.getItem('cart'));
        }
        return this.items.reduce((total, item) => total + item.price * item.quantity, 0);
    },

    showCart() {
        const savedCart = localStorage.getItem('cart');
        // This check ensures that savedCart is not null and is a string.
        if (savedCart && typeof savedCart === 'string') {
            try {
                const parsedCart = JSON.parse(savedCart);
                cart.items = parsedCart;
                if (this.getSum) {
                    this.getSum();
                }
            } catch (e) {
                console.error('Error parsing saved cart:', e);
                // Handle the error or reset the cart
                cart.items = []; // Reset the cart if the data is corrupted
            }
        }
    },
    clearCart() {
        localStorage.setItem('cart', JSON.stringify([]));
    }
});

export function loadCart() {
    const savedCart = localStorage.getItem('cart');
    // This check ensures that savedCart is not null and is a string.
    if (savedCart && typeof savedCart === 'string') {
        try {
            const parsedCart = JSON.parse(savedCart);
            cart.items = parsedCart;
            this.getSum();
        } catch (e) {
            console.error('Error parsing saved cart:', e);
            // Handle the error or reset the cart
            cart.items = []; // Reset the cart if the data is corrupted
        }
    }
}

export function getCartItems() {
    const savedCart = localStorage.getItem('cart');
    let cartItems = [];

    if (savedCart && typeof savedCart === 'string') {
        try {
            const parsedCart = JSON.parse(savedCart);
            cartItems = parsedCart;
        } catch (e) {
            console.error('Error parsing saved cart:', e);

            cartItems = [];
        }
    }
    return cartItems;
}
