<template>
  <ion-modal id="acceptModal" class="fullscreen" style="border:1px solid #fff;" :is-open="showModal">
    <ion-content style="border:4px solid #fff;"  :style="' --background: '+(user && user.bg ? user.bg : '')+';'">
      <ion-row class="modal-padding-b">
        <ion-col size="11"></ion-col>
        <ion-col size="1" class="ion-text-right">
          <ion-buttons class="d-block">
            <ion-button color="primary" fill="outline" size="large" @click="showModal = false">
              <ion-icon :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>
      <ion-row class="modal-padding-b">
      <h2 :style="'color:' + (user && user.primary_color ? user.primary_color : '#fff')">Aby zapisać się do newslettera musisz potwierdzić swoje dane, przez weryfikację e-mail lub podpis.</h2>
      </ion-row>
      <ion-row v-if="sign" class="ion-padding">
        <ion-col class="signature-container">
          <signature-pad @input="handleDraw" width="768" height="140"></signature-pad>
        </ion-col>
      </ion-row>
      <ion-row style="text-align: right" class="ion-padding">
        <ion-col size="12">
          <ion-button fill="outline" v-if="!sign" @click="acceptEmail()" expand="block">Potwierdzenie E-mail</ion-button>
          <ion-button fill="outline" color="danger" v-else @click="back()" expand="block">Cofnij</ion-button>
        </ion-col>
        <ion-col size="12">
          <ion-button fill="outline" v-if="!sign" @click="acceptText()" expand="block">Potwierdzenie podpisem</ion-button>
          <ion-button fill="outline" v-else @click="closeModal()" expand="block">Zapisz podpis</ion-button>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-modal>
</template>

<script>
import SignaturePad from "@/views/components/SignaturePad";
import {
  IonIcon
} from '@ionic/vue';
import {Storage} from "@ionic/storage";
import { applyTheme } from '@/services/theme';
export default {
  name: "ModalAcceptPromotional",
  components: {SignaturePad, IonIcon},
  data: function () {
    return {
      showModal: false,
      sign: false,
      signature: '',
      user: null
    }
  },
  async mounted() {
    const storage = new Storage();
    await storage.create();
    await storage.get('config').then(res => {
      this.user = res.data;
      applyTheme(res.data);
    });
    },
  methods: {
    acceptEmail() {
      this.$emit('acceptEmail');
    },
    acceptText() {
      this.sign = true;
    },
    back() {
      this.signature = null;
      this.sign = false;
    },
    handleDraw(event) {
      this.signature = event;
    },
    closeModal() {
      this.$emit('acceptSign', {signature: this.signature})
      this.showModal = false;
    }
  }
}
</script>

<style scoped>
.signature-container ion-content {
  --ion-background-color: none;
}
</style>
