<template>
  <ion-content style="width: 100%;height: 250px">
    <canvas
        style="border: 1px solid black; background: #fff;"
        ref="signatureCanvas"
        :width="width"
        :height="height"
        @mousedown="startDrawing"
        @mousemove="draw"
        @mouseup="endDrawing"
        @mouseleave="endDrawing"
        @touchstart.prevent="startDrawing"
        @touchmove.prevent="draw"
        @touchend.prevent="endDrawing"
        class="signature-canvas"
    ></canvas>
    <ion-button @click="clearCanvas">Clear</ion-button>
    </ion-content>
</template>

<script>
import {Storage} from "@ionic/storage";
import { applyTheme } from '@/services/theme';

export default {
  name: 'SignaturePad',
  props: {
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 250
    }
  },
  data() {
    return {
      user: null
    }
  },
  async mounted() {
    this.initCanvas();
    const storage = new Storage();
    await storage.create();
    await storage.get('config').then(res => {
      this.user = res.data;
      applyTheme(res.data);
    });
  },
  methods: {
    initCanvas() {
      const canvas = this.$refs.signatureCanvas;
      this.ctx = canvas.getContext('2d');
      this.ctx.strokeStyle = '#000';
      this.ctx.lineWidth = 2;
    },
    startDrawing(event) {
      this.isDrawing = true;
      const { offsetX, offsetY } = this.getOffset(event);
      this.ctx.beginPath();
      this.ctx.moveTo(offsetX, offsetY);
    },
    draw(event) {
      if (!this.isDrawing) return;
      const { offsetX, offsetY } = this.getOffset(event);
      this.ctx.lineTo(offsetX, offsetY);
      this.ctx.stroke();
    },
    getOffset(event) {
      if (event.touches) {
        event = event.touches[0];
      }
      return {
        offsetX: event.clientX - event.target.getBoundingClientRect().left,
        offsetY: event.clientY - event.target.getBoundingClientRect().top
      };
    },
    endDrawing() {
      this.isDrawing = false;
      const dataUrl = this.$refs.signatureCanvas.toDataURL('image/png')
      this.$emit('input', dataUrl);
    },
    clearCanvas() {
      this.ctx.clearRect(0, 0, this.width, this.height);
    },
    saveSignature() {
      const dataUrl = this.$refs.signatureCanvas.toDataURL('image/png');
      // Send the base64 image to the backend
    },
    // ... (other methods remain unchanged)
  }
};
</script>

<!-- ... (style remains unchanged) -->
