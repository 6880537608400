<template>
  <ion-page id="promoModal" class="fullscreen">
    <ion-content :style="' --background: '+(user && user.bg ? user.bg : '')+';'">
      <ion-row class="modal-padding-t">
        <ion-col>
          <ion-text color="primary">
            <h2>{{user?.promotion_text ? user.promotion_text : ''}}</h2>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-list style="background: none;" class="ion-padding">
        <ion-radio-group v-model="gender">
          <ion-row>
            <!-- Kolumna dla "Pan" -->
            <ion-col size="12" size-md="2">
              <ion-item lines="none" class="background-none">
                <ion-label color="primary">Pan</ion-label>
                <ion-radio value="M"></ion-radio>
              </ion-item>
            </ion-col>

            <!-- Kolumna dla "Pani" -->
            <ion-col size="12" size-md="2">
              <ion-item lines="none" class="background-none">
                <ion-label color="primary">Pani</ion-label>
                <ion-radio value="W"></ion-radio>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-radio-group>

        <ion-row>
          <ion-col size="12" size-md="6">
            <ion-item class="border-bottom-primary background-none">
              <ion-label position="floating" color="primary">Imię</ion-label>
              <ion-input v-model="name"></ion-input>
              <p v-if="errors.name" style="color: red;">{{errors.name}}</p>
            </ion-item>
          </ion-col>
          <ion-col size="12" size-md="6">
            <ion-item class="border-bottom-primary background-none">
              <ion-label position="floating" color="primary">Nazwisko</ion-label>
              <ion-input v-model="surname"></ion-input>
            </ion-item>
            <p v-if="errors.surname" style="color: red;">{{errors.surname}}</p>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12" size-md="6">
            <ion-item class="border-bottom-primary date-item background-none">
              <ion-label color="primary">Data urodzenia</ion-label>
              <ion-datetime-button color="primary" datetime="date"></ion-datetime-button>
              <ion-modal :keep-contents-mounted="true">
                <ion-datetime id="date" v-model="birthdate" @ionChange="dateChange($event)"
                              presentation="date"></ion-datetime>
              </ion-modal>
            </ion-item>
            <p v-if="errors.birthdate" style="color: red;">{{errors.birthdate}}</p>

          </ion-col>
          <ion-col size="12" size-md="6">
            <ion-item class="border-bottom-primary background-none">
              <ion-label position="floating" color="primary">Adres e-mail</ion-label>
              <ion-input type="email" v-model="email"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item class="border-bottom-primary background-none">
              <ion-label position="floating" color="primary">Adres (dobrowolnie)</ion-label>
              <ion-input v-model="address"></ion-input>
            </ion-item>
            <p v-if="errors.address" style="color: red;">{{errors.address}}</p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-right">
            <ion-button fill="outline" color="danger" @click="showModal = false">Anuluj</ion-button>
            <ion-button fill="outline" v-if="!confirmNewsletter" @click="saveNewsletter" color="primary">Potwierdź dane</ion-button>
            <ion-button fill="outline" v-if="confirmNewsletter" @click="saveData()" color="primary">Zapisz dane</ion-button>
          </ion-col>
        </ion-row>

      </ion-list>
    </ion-content>
    <ModalAcceptPromotional @acceptSign="acceptSign" @acceptEmail="acceptEmail"
                            ref="acceptModal"></ModalAcceptPromotional>
    <ion-loading :is-open="isLoading" :message="$t('load_data')"/>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonItem,
  IonInput,
  IonRow,
  IonCol,
  IonModal,
  IonButton,
  IonRadio,
  IonRadioGroup,
  IonDatetimeButton
} from '@ionic/vue';
import ModalAcceptPromotional from "@/views/components/modals/ModalAcceptPromotional";
import {Storage} from "@ionic/storage";
import {applyTheme} from '@/services/theme';
import axios from 'axios';
import {close} from "ionicons/icons";

export default {
  name: "PromotionalModal",
  components: {
    ModalAcceptPromotional,
    IonPage,
    IonContent,
    IonRow,
    IonCol,
    IonModal,
    IonButton,
    IonItem,
    IonRadio,
    IonInput,
    IonRadioGroup,
    IonDatetimeButton
  },
  data: function () {
    return {
      showModal: false,
      gender: '',
      name: '',
      surname: '',
      birthdate: null,
      email: '',
      address: '',
      canvas: null,
      confirmNewsletter: false,
      user: null,
      activationType: '',
      isLoading: false,
      close: close,
      errors: [],
      isError: false
    }
  },
  async mounted() {
    const storage = new Storage();
    await storage.create();
    await storage.get('config').then(res => {
      this.user = res.data;
      applyTheme(res.data);
    });
  },
  methods: {
    validateForm() {
      this.errors = [];
      this.isError = false;
      if (!this.name) {
        this.errors.push('Imię jest wymagane.');
        this.isError = true;
      }
      if (!this.surname) {
        this.errors.push('Nazwisko jest wymagane.');
        this.isError = true;
      }
      if (!this.birthdate) {
        this.errors.push('Data urodzenia jest wymagana.');
        this.isError = true;
      }
      if (!this.email) {
        this.errors.push('Adres e-mail jest wymagany.');
        this.isError = true;
      }

     return this.isError
    },
    acceptSign(event) {
      this.canvas = event.signature;
      this.activationType = 'sign';
      this.$refs.acceptModal.showModal = false;
      this.confirmNewsletter = true;
    },
    acceptEmail() {
      this.activationType = 'email';
      this.$refs.acceptModal.showModal = false;
      this.confirmNewsletter = true;
    },
    saveNewsletter() {
      this.validateForm();
      if (this.errors.length === 0) {
        this.$refs.acceptModal.showModal = true;
      } else {
        alert(this.errors.join('\n'));
      }
    },
    dateChange(event) {
      const arr = event.detail.value.split('T');
      if (arr?.length) {
        this.birthdate = arr[0];
      }
    },
    async saveData() {
      const data = {
        gender: this.gender,
        first_name: this.name,
        last_name: this.surname,
        birthdate: this.birthdate,
        email: this.email,
        address: this.address,
        signature: this.canvas,
        activation_type: this.activationType
      };
      try {
        this.isLoading = true;
        // Add API url
        await axios.post(process.env.VUE_APP_API_URL + '/cart/recipient', data, {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }})
            .then((response) => {
              this.isLoading = false;
              this.closeModal();
              this.clearForm();

              alert('Dziekujemy za zapisanie do newslettera')
            })
      } catch (error) {
        this.isLoading = false;
        alert(error);
      }
    },
    clearForm() {
      this.gender = '';
      this.name = '';
      this.surname = '';
      this.email = '';
      this.address = '';
      this.confirmNewsletter = false;
      this.activationType = '';
    },
    closeModal() {
      this.showModal = false;
    }
  }
}
</script>
<style scoped>
.bordered-item {
  --border-width: 1px;
  --border-style: solid;
  --border-color: #d4d4d4; /* przykładowy kolor */
}

.custom-datetime-input {
  --background: white;
  --border-color: #d4d4d4;
  --border-style: solid;
  --border-width: 1px;
  --border-radius: 4px;
  --padding-start: 8px;
  --padding-end: 8px;
}

#time-button {
  display: none !important;
}

ion-radio.ios::part(container) {
  width: 20px;
  height: 20px;

  border: 2px solid #ddd;
  border-radius: 4px;
}

.radio-checked.ios::part(container) {
  border-color: #6815ec;
}

.ion-color-secondary {
  --ion-color-base: #fff !important;
  --ion-color-base-rgb: var(--ion-color-secondary-rgb, 61, 194, 255) !important;
  --ion-color-contrast: var(--ion-color-secondary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: #fff !important;
  --ion-color-tint: #fff !important;
}

.modal-custom-background {
  /* --background: #212121 !important; Ustawienie zmiennej CSS dla tła */
  /* --ion-background-color: #fff9ec; */
}

.border-bottom-primary {
  border-bottom: 1px solid var(--ion-color-primary);
}

.date-item {
  height: 100%;
}

ion-item::part(native) {
  border-bottom: none !important;
}

.background-none {
  --background: none;
}
ion-radio.ios::part(container) {
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 4px;
}
</style>
