<template>
  <ion-page>
    <ion-content :style="' --background: url('+(user && user.bg_img ? user.bg_img : '')+');'">
      <ion-row>
        <ion-col size="10" size-sm="4" size-md="11" size-lg="4" class="ion-text-center ion-padding-bottom">
          <ion-text color="primary">
            <h3 class="text-uppercase">{{ $t('Szczegóły zamówienia') }}</h3>
          </ion-text>
        </ion-col>
      </ion-row>
      <hr/>
      <!-- Twoje elementy koszyka i inne -->
      <ion-row v-for="item in items" :key="item.id" class="ion-padding-b">
        <ion-col size="12" size-md="4">
          <ion-text color="primary">{{ item.name }}</ion-text>
        </ion-col>
        <ion-col size="3" size-md="2">
          <ion-text color="primary">{{ item.price }} PLN
          </ion-text>
        </ion-col>
        <ion-col size="7" size-md="3" class="ion-align-items-center"
                 style="display: flex; justify-content: center; align-items: center;">
          <ion-button style="    width: 50px;
    height: 25px;
    padding: 0px;" @click="decrease(item)" fill="outline" class="ion-color-primary" color="primary">
            <ion-icon color="primary" class="ion-color-primary" name="remove-outline"></ion-icon>
          </ion-button>
          <ion-text color="primary" style="margin: 0 10px;">{{ item.quantity }}</ion-text>
          <ion-button style="    width: 50px;
    height: 25px;
    padding: 0px;" @click="increase(item)" fill="outline" color="primary">
            <ion-icon color="primary" class="ion-color-primary" name="add-outline"></ion-icon>
          </ion-button>
        </ion-col>

        <ion-col size="2" size-md="1">
          <ion-button style="width: 50px;
                              height: 25px;
                              padding: 0px;" fill="outline" @click="removeProduct(item.id)" color="danger">
            <ion-icon name="remove-outline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row class="ion-padding-b" style="text-align: right;">
        <ion-col size="2" size-md="9"></ion-col>
        <ion-col size="10" size-md="3" style="padding-top: 10px;padding-bottom: 10px; padding-right: 10px">
          <ion-text color="primary" class="ion-text-right">
            Podsumowanie: {{ sum }} PLN
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="w-100" style="justify-content: right">
        <ion-col size-md="2" size="3">
          <ion-button fill="outline" @click="makeOrder()">Zamów</ion-button>
        </ion-col>
      </ion-row>
    </ion-content>

    <ion-modal id="qr_code_modal" style="border:1px solid #fff;" :is-open="showQrCodeModal">
      <ion-content :style="' --background: '+(user && user.bg ? user.bg : '')+';'">
        <ion-row class="modal-padding-b">
          <ion-col size="11"></ion-col>
          <ion-col size="1" class="ion-text-right">
            <ion-buttons class="d-block">
              <ion-button color="primary" fill="clear" size="large" @click="showQrCodeModal = false">
                <ion-icon :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-center ion-color-primary">
            <ion-text color="primary" class="ion-color-primary"><h3>Pokaż ten kod pracownikowi</h3></ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-margin-top">
            <img v-if="qrCode" :src="qrCode" alt="kod">
          </ion-col>
        </ion-row>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
import {cart, getCartItems} from '@/services/cart';
import {Storage} from "@ionic/storage";
import {applyTheme} from '@/services/theme';
import {close} from "ionicons/icons";
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButton,
  IonModal, IonButtons, IonIcon
} from '@ionic/vue';
import axios from "axios";

export default {
  name: 'CartComponent',
  components: {
    IonIcon, IonButtons,
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonButton,
    IonModal
  },
  setup() {
    const items = getCartItems();
    return items;
  },
  data: function () {
    return {
      showModal: false,
      items: null,
      sum: 0,
      user: null,
      enable_order: false,
      close: close,
      order: {
        total: 0,
        table_id: null,
        items: []
      },
      qrCode: null,
      showQrCodeModal: false
    }
  },

  async mounted() {
    const storage = new Storage();
    await storage.create();
    await storage.get('config').then(res => {
      if (res && res.data) {
        this.user = res.data;
        applyTheme(res.data);
        this.enable_order = res.data.enable_order;
      }
    });

  },
  methods: {
    // Możesz eksportować cart bezpośrednio lub utworzyć funkcję pomocniczą
    removeProduct(productId) {
      cart.removeProduct(productId, true);
      this.items.splice(productId, 1);
      this.getSum()
    },
    openModal() {
      this.showModal = true;
      this.items = JSON.parse(localStorage.getItem('cart'));
      this.getSum();
    },
    decrease(item) {
      cart.removeProduct(item.id)
      if (item.quantity > 1) {
        item.quantity--;
        item.price = (item.base_price * item.quantity).toFixed(2);
        this.getSum()
      }
    },
    async makeOrder() {
      this.order.total = cart.total
      cart.items.forEach(item => {
        this.order.items.push({product_id: item.id, quantity: item.quantity})
      })
      const storage = new Storage();
      await storage.create();
      const table = storage.get('table').then(res => {
        if (res.id) {
          this.order.table_id = res.id;
        }
        axios.post(process.env.VUE_APP_API_URL + '/cart/order', this.order, {
          headers: {
            'Authorization': `Bearer ${localStorage.token}`
          }
        }).then((response) => {
          this.qrCode = response.data.qr;
          this.order = null;
          cart.clearCart();
          cart.items.forEach(item => {
            cart.removeProduct(item.id)
          });
          this.order = {
            total: 0,
            table_id: null,
            items: []
          },
              this.items = null;
          this.showQrCodeModal = true;
        });
      });
    },
    increase(item) {
      cart.addProduct(item);
      console.log(item);
      item.quantity++;
      item.price = (item.base_price * item.quantity).toFixed(2);
      this.getSum()
    },

    addProduct(item) {
      cart.addProduct(item);
      this.getSum();
    },

    getSum() {
      let sum = 0;
      this.items = JSON.parse(localStorage.getItem('cart'));
      if (this.items) {
        this.items.forEach(el => {
          sum += el.price * el.quantity;
        });
      }

      this.sum = sum.toFixed(2);
    },

    ionViewDidEnter() {
      this.items = getCartItems();
      this.getSum();
    },
  },
  // ...other options like 'data', 'methods', 'computed', etc.
};
</script>
