import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import {IonContent, IonicVue, IonModal} from '@ionic/vue';
import { IonImg } from '@ionic/vue';
import { IonText } from '@ionic/vue';
import { IonCol } from '@ionic/vue';
import { IonLabel } from '@ionic/vue';
import { IonRow } from '@ionic/vue';
import { IonButton } from '@ionic/vue';

import { IonDatetime } from '@ionic/vue';
import { IonList } from '@ionic/vue';
import { IonLoading } from '@ionic/vue';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import {createI18n} from "vue-i18n";
/* Theme variables */
import './theme/variables.css';
const localeS = localStorage.getItem('locale') ?? 'es';
export const globalizationList = {
  en: {
    Type: "Type",
    Region: "Region",
    our_wines: "Our Wines",
    discover: "discover",
    quantity:'Quantity',
    Language: "Language",
    Order: "Order",
    English: "English",
    Spanish: "Español",
    Home: "Home",
    champagne: 'Champagne',
    red: 'Red',
    white: 'White',
    rose: 'Rosé',
    Country: 'Country',
    type: 'Type',
    select_type: "Wine type",
    Champagne: 'Champagne',
    Red: 'Red',
    White: 'White',
    Rose: 'Rosé',
    italy: 'Italy',
    spain: 'Spain',
    france: 'France',
    united_states: 'United States',
    australia: 'Australia',
    chile: 'Chile',
    argentina: 'Argentina',
    south_africa: 'South Africa',
    portugal: 'Portugal',
    germany: 'Germany',
    sweden: 'Sweden',
    switzerland: 'Switrzerland',
    netherlands: 'The Netherlands',
    new_zealand: 'New Zealand',
    china: 'China',
    romania: 'Romania',
    russia: 'Russia',
    hungary: 'Hungary',
    uruguay: 'Uruguay',
    brazil: 'Brazil',
    serbia: 'Serbia',
    moldova: 'Moldova',
    peru: 'Peru',
    morocco: 'Morocco',
    other: 'Other...',
    sparklings: 'sparklings',
    Sparklings: 'Sparklings',
    net_required: 'In order to update the wine list, a tablet connection to the Internet is required.',
    load_data: 'Ładowanie karty...',
    logging_in: 'Logowanie'
  },
  es: {
    quantity:'Cantidad',
    Type: "Tipo",
    Region: "Región",
    our_wines: "Nuestros vinos",
    discover: "descubre",
    Language: "Idioma",
    Order: "Pedir",
    English: "English",
    Spanish: "Español",
    Home: "Home",
    champagne: 'Champán',
    red: 'Tinto',
    white: 'Blanco',
    rose: 'Rosado',
    Country: 'País',
    type: 'Tipo',
    select_type: "Tipos de vino",
    Champagne: 'Champán',
    Red: 'Tinto',
    White: 'Blanco',
    Rose: 'Rosado',
    italy: 'Italia',
    spain: 'España',
    france: 'Francia',
    united_states: 'Estados Unidos',
    australia: 'Australia',
    chile: 'Chile',
    argentina: 'Argentina',
    south_africa: 'Sudáfrica',
    portugal: 'Portugal',
    germany: 'Alemania',
    sweden: 'Suecia',
    switzerland: 'Suiza',
    netherlands: 'Países Bajos',
    new_zealand: 'Nueva Zelanda',
    china: 'China',
    romania: 'Rumanía',
    russia: 'Rusia',
    hungary: 'Hungría',
    uruguay: 'Uruguay',
    brazil: 'Brasil',
    serbia: 'Serbia',
    moldova: 'Moldavia',
    peru: 'Perú',
    morocco: 'Marruecos',
    other: 'Otros...',
    sparklings: 'espumosos',
    Sparklings: 'Espumosos',
    net_required: 'Para actualizar la lista de vinos, se requiere una conexión a Internet de una tablet.',
    load_data: 'Ładowanie karty...',
    logging_in: 'Logowanie'
  }
}
const i18n = createI18n({
  locale: localeS.toString(), // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: globalizationList, // set locale messages
  preserveDirectiveContent: true
})

const app = createApp(App)
  .use(IonicVue)
  .use(router)
    .use(i18n);

app.component('ion-img', IonImg);
app.component('ion-text', IonText);
app.component('ion-col', IonCol);
app.component('ion-label', IonLabel);
app.component('ion-row', IonRow);
app.component('ion-button', IonButton);
app.component('ion-datetime', IonDatetime);
app.component('ion-list', IonList);
app.component('ion-loading', IonLoading);
app.component('ion-content', IonContent);
app.component('ion-modal', IonModal);
router.isReady().then(() => {
  app.mount('#app');
});
