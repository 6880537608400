<template>
  <ion-page>
    <ion-content ref="homePageContent" :style="`--background: url(${user && user.bg_img ? user.bg_img : ''});`">
      <div :class="{'container-blurred': vines?.length}">

        <!-- Logo Container -->
        <div class="logo-container" v-if="user && user.logo">
          <ion-img :src="user.logo" width="100"></ion-img>
        </div>
        <div v-if="!showTypeModal || selectedVine">
          <ion-button fill="clear" @click="handleBack()">
            <ion-icon :icon="chevronBackOutline"></ion-icon>
          </ion-button>
        </div>
        <!-- Type Modal -->
        <div v-if="showTypeModal">
          <ion-grid>
<!--            <ion-row>-->
<!--              <ion-col size="12">-->
<!--                <ion-text color="primary" class="ion-text-center">-->
<!--                  <h1>{{ $t('Menu select') }}</h1>-->
<!--                </ion-text>-->
<!--              </ion-col>-->
<!--            </ion-row>-->

            <!-- Language Selection -->
<!--            <ion-row>-->
<!--              <ion-col size="12" class="ion-text-right">-->
<!--                <ion-button fill="clear" @click="showLanguageModal = true">-->
<!--                  {{ $t('Language') }}-->
<!--                  <ion-icon name="chevron-down-outline"></ion-icon>-->
<!--                </ion-button>-->
<!--              </ion-col>-->
<!--            </ion-row>-->

            <!-- Category Buttons -->
            <ion-row class="ion-margin-top">
              <ion-col v-for="cat in categories" :key="cat.id" size="6" size-md="4" size-lg="3">
                <ion-button style="text-wrap: pretty !important;" fill="outline" expand="block" @click="handleTypeSelected(cat)" :value="cat.id">
                  {{ $t(cat.name) }}
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>

        <div v-if="selectedVine && showModal">
<!--          <ion-row class="ion-padding-end">-->
<!--            <ion-col size="11"></ion-col>-->
<!--            <ion-col size="1" class="ion-text-right">-->
<!--              <ion-buttons class="d-block">-->
<!--                <ion-button color="primary" fill="clear" size="large" @click="closeModal()">-->
<!--                  <ion-icon :icon="close"></ion-icon>-->
<!--                </ion-button>-->
<!--              </ion-buttons>-->
<!--            </ion-col>-->
<!--          </ion-row>-->
          <ion-row class="modal-padding-t">
            <ion-col size="12" size-sm="6" size-lg="6" style="    justify-content: center;display: contents;">
              <ion-img v-if="selectedVine.image" class="modal-wine-photo"
                       :src="'https://api-restaurants.bwcoders.com/api/image-product/'+selectedVine.image"></ion-img>
            </ion-col>
            <ion-col size="12" size-sm="6" size-lg="6">
              <ion-row class="ion-margin-bottom">
                <ion-col>
                  <ion-text color="primary">
                    <h2>{{ selectedVine.name }}</h2>
                  </ion-text>
                </ion-col>
              </ion-row>
              <ion-row v-if="selectedVine.description" class="ion-margin-bottom">
                <ion-col style="    text-align: left;">
                  <ion-text color="primary">
                    <span>{{ selectedVine.description }}</span>
                  </ion-text>
                </ion-col>
              </ion-row>
              <hr v-show="selectedVine.attributes"/>

              <ion-row v-if="selectedVine.attributes">
                <ion-col style="    text-align: left;" size-md="6" size="12" v-for="attr in getWineAttributes(selectedVine)" :key="attr.key">
                  <ion-text color="primary">
                    <span><strong>{{ attr.key }}:</strong></span> <span>{{ attr.value }}</span>
                  </ion-text>
                </ion-col>
              </ion-row>
              <ion-row v-if="selectedVine.price" class="ion-margin-top">
                <ion-col style="text-align: right" size="12">
                  <div>
                    <div class="font-serif text-italic" style="margin-top: 5px;">
                      <ion-text color="primary">
                      <span>
                        Cena: {{ selectedVine.price }} PLN
                      </span>
                      </ion-text>
                    </div>
                    <div v-if="selectedVine.small_price && selectedVine.small_price > 0"
                         class="font-serif text-italic details-price details-price-mt"
                         style="margin-top: 20px;padding-top: 5px">
                      <ion-text color="primary">
                        <ion-icon class="details-icon-glass" :icon="wine" style="margin-right: 12px;"></ion-icon>
                        {{ selectedVine.small_price }} $
                      </ion-text>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row class="mb-3 pb-3">
                <ion-col style="text-align: right" size="12" class="text-right">
                  <ion-button fill="outline" color="primary" @click="addToCart(selectedVine)">Dodaj do zamówienia</ion-button>
                </ion-col>
              </ion-row>
              <ion-row v-if="selectedVine.recommended_products?.length">
                <ion-text color="primary">
                  <h3>Recommended products</h3>
                </ion-text>
              </ion-row>
              <swiper ref="mySwiper" v-if="selectedVine.recommended_products?.length"
                      :slides-per-view="orientation == 'y' ? 1 : 4" :options="swiperOptions">
                <swiper-slide v-for="recommendedProduct in selectedVine.recommended_products"
                              :key="recommendedProduct.id">
                  <ion-row @click="handleClickSlider(recommendedProduct)">
                    <ion-col>
                      <ion-img v-if="images[recommendedProduct.image]" class="modal-wine-photo"
                               :src="images[recommendedProduct.image]"></ion-img>
                    </ion-col>
                  </ion-row>
                  <ion-row @click="handleClickSlider(recommendedProduct)">
                    <ion-col>
                      <ion-text color="primary">
                        <h2 class="ion-no-margin">{{ recommendedProduct.name }}</h2>
                      </ion-text>
                    </ion-col>
                  </ion-row>
                  <ion-row v-if="selectedVine.description" class="product-desc ion-margin-bottom">
                    <ion-col>
                      <ion-text color="primary">
                        <span>{{ selectedVine.description }}</span>
                      </ion-text>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col>
                      <ion-text color="primary" class="text-italic font-serif">
                        <p class="ion-no-margin">{{ recommendedProduct.price }} PLN</p>
                      </ion-text>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col>
                     <ion-button color="primary" fill="outline" @click="addToCart(recommendedProduct)">Add to cart</ion-button>
                    </ion-col>
                  </ion-row>
                </swiper-slide>
              </swiper>

            </ion-col>
          </ion-row>
        </div>

        <!--SLIDER-->
        <div v-if="vines?.length && !selectedVine">
          <swiper ref="mySwiper" :pagination="true" :modules="modules" :slides-per-view="orientation == 'y' ? 1 : 4" :options="swiperOptions">
            <swiper-slide @click="handleClickSlider(vine)" v-for="vine in vines" :key="vine.id">
              <ion-row class="d-flex">
                <img class="m-auto swiper-lazy swiper-image" style="max-height: 200px" :data-src="'https://api-restaurants.bwcoders.com/api/image-product/'+vine.image" :src="'https://api-restaurants.bwcoders.com/api/image-product/'+vine.image" :alt="vine.name"/>
              </ion-row>
              <ion-row class="ion-text-center">
                <ion-col class="slider-wine-name text-uppercase ion-padding-left ion-padding-right">
                  <ion-text color="primary">
                    <h6 class="slider-wine-h4">
                      {{ vine.name }}
                    </h6>
                  </ion-text>
                </ion-col>
              </ion-row>
              <ion-row class="ion-text-center">
                <ion-col class="font-serif text-italic">
                  <ion-text color="primary">
                    <h6 v-if="vine.price">
                      {{ vine.price }} PLN
                    </h6>
                  </ion-text>
                </ion-col>
              </ion-row>
            </swiper-slide>
          </swiper>
        </div>
        <!-- KONIEC SLIDERA-->
      </div>
    </ion-content>
    <!-- Modal z wyborem jezyka -->
    <ion-modal class="fullscreen" :is-open="showLanguageModal">
      <ion-content :style="' --background: url('+(user && user.bg_img ? user.bg_img : '')+');'">
        <ion-row class="modal-padding-b">
          <ion-col size="11"></ion-col>
          <ion-col size="1" class="ion-text-right">
            <ion-buttons class="d-block">
              <ion-button color="primary" fill="clear" size="large" @click="closeModalLanguage()">
                <ion-icon :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin-top ion-text-center modal-padding-t">
          <ion-col>
            <ion-row class="ion-text-center">
              <ion-col size="12" size-md="4" size-lg="4" class="ion-text-center"></ion-col>
              <ion-col size="12" size-md="4" size-lg="4" class="ion-text-center">
                <ion-button class="rounded-0" size="large" fill="outline" expand="block" @click="changeLanguage('en')"
                            labelPlacement="start">{{ $t('English') }}
                </ion-button>
              </ion-col>
            </ion-row>
            <ion-row class="ion-text-center">
              <ion-col size="12" size-md="4" size-lg="4" class="ion-text-center"></ion-col>
              <ion-col size="12" size-md="4" size-lg="4" class="ion-text-center">
                <ion-button class="rounded-0" size="large" fill="outline" expand="block" @click="changeLanguage('es')"
                            labelPlacement="start">{{ $t('Spanish') }}
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-col>

        </ion-row>
      </ion-content>
    </ion-modal>
    <ion-loading
        :is-open="isLoading"
        :message="$t('load_data')"
    />
  </ion-page>
</template>

<script>
import '../theme/variables.css';
import {
  IonContent,
  IonPage,
  IonRow,
  IonImg,
  IonCol,
  IonModal,
  IonIcon,
  IonButton,
  IonButtons,
  IonLoading,
  toastController
} from '@ionic/vue';
import {Storage} from "@ionic/storage";
import {Swiper, SwiperSlide} from "swiper/vue";
import {close, wine, chevronBackOutline} from 'ionicons/icons';
import 'swiper/css';
import axios from 'axios'
import {Preferences} from '@capacitor/preferences';
import {cart} from '../services/cart';
import {applyTheme} from '@/services/theme';
import {Pagination} from "swiper";
import router from '../router';

export default {
  name: 'WineIndex',
  components: {
    IonContent,
    IonPage,
    Swiper,
    SwiperSlide,
    IonRow,
    IonImg,
    IonCol,
    IonModal,
    IonIcon,
    IonButton,
    IonButtons,
    IonLoading
  },
  data() {
    return {
      slideOpts: {
        initialSlide: 2,
        speed: 400,
      },
      vines: [],
      baseUrl: null,
      showModal: false,
      selectedVine: null,
      close: close,
      wine: wine,
      chevronBackOutline: chevronBackOutline,
      selectedQuantity: 1,
      showTypeModal: false,
      showCountryModal: false,
      categories: [],
      selectedTypes: [],
      selectedCountries: [],
      typesModel: null,
      isLoading: false,
      online: navigator.onLine,
      test: true,
      requests: [],
      countries: [],
      vm: this,
      isConnected: null,
      showLanguageModal: false,
      language: localStorage.getItem('locale') ?? 'es',
      swiperOptions: {
        preloadImages: true,
        lazy: {
          loadPrevNext: true,
        },
      },
      isSmallPriceExists: false,
      orientation: null,
      smallPrice: true,
      isOpenAlert: false,
      images: [],
      user: null,
      modules: []
    }
  },
  methods: {
    handleBack() {
      if (this.selectedVine) {
        this.closeModal();
      }
      else {
        this.goToHome();
      }
    },
    addToCart(vine) {
      cart.addProduct(vine);
      this.presentToast();
      setTimeout(() => {
        this.$refs.homePageContent.$el.scrollToBottom(500);
      }, 1000);
    },
    async presentToast() {
      const toast = await toastController.create({
        message: 'Product added to Cart',
        duration: 3000,
        position: 'top',
      });

      await toast.present();
    },
    async getImageOrFetch(vine) {
      // Sprawdzanie, czy obrazek jest dostępny w pamięci podręcznej
      const {value} = await Preferences.get({key: vine.image});
      if (!value) {
        // Pobieranie obrazka, jeśli nie jest dostępny w pamięci podręcznej
        //const response = await axios.get(imageUrl, { responseType: 'arraybuffer' });
        // value = Buffer.from(response.data, 'binary').toString('base64')
        // Dodanie obrazka do pamięci podręcznej
        return vine.image;
      }

      return value.toString();
    },
    getCountry(vine) {
      // if (vine.category_data[0] && vine.category_data[0].slug == 'Country' && vine.category_data[0].name != 'other') {
      //   return vine.category_data[0].name
      // } else {
      //   if (vine.category_data[1] && vine.category_data[1].name != 'other') {
      //     return vine.category_data[1].name
      //   } else {
      //     return null;
      //   }
      // }
      return null;
    },
    async checkOrientationAndScreenSize() {
      const screenSize = Math.sqrt(window.screen.width ** 2 + window.screen.height ** 2) / 96; // 96 jest typowym DPI dla ekranów
      // Jeżeli
      // console.przekątna jest mniejsza lub równa 6 cali, to jest to telefon
      if (screenSize <= 12) {
        if (window.innerWidth > window.innerHeight) {
          this.orientation = 'x' // Poziomy
        } else {
          this.orientation = 'y';
        }
      }
    },
    goToHome() {
      this.vines = [];
      this.showTypeModal = true;
      this.loadCategories();
    },
    resetVines() {
      this.isSmallPriceExists = 0
      this.selectedTypes = [];
      this.selectedCountries = [];
      // this.loadVines(1, 0);
      this.loadCategories();
      this.showTypeModal = true;
    },
    changeLanguage(langugage) {
      this.$i18n.locale = langugage;
      localStorage.setItem('locale', langugage);
      this.language = langugage;
      this.showLanguageModal = false;
    },

    async handleTypeSelected(subCat) {
      this.selectedTypes = [];
      this.selectedTypes = [subCat.id];
      await this.loadCategories(subCat.id);
    },

    handleCountrySelected(subCat) {
      this.selectedCountries = [];
      this.selectedCountries.push(subCat.id);
      this.saveCountries();
    },
    async saveTypes() {
      await this.loadVines()
      this.closeModalType();
    },
    saveCountries() {
      this.loadVines()
      this.closeModalCountry();
    },
    closeModalType() {
      this.showTypeModal = false;
    },
    closeModalLanguage() {
      this.showLanguageModal = false;
    },
    closeModalCountry() {
      this.showCountryModal = false;
    },
    handleClickSlider(vine) {
      this.selectedVine = vine;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false
      this.selectedVine = null;
      this.selectedQuantity = 1
    },
    getWineAttributes(wine) {
      // Zwróć klucze i wartości jako pary klucz-wartość.
      return wine.attributes;
    },
    decrease() {
      if (this.selectedQuantity > 1) {
        this.selectedQuantity--;
      }
    },
    increase() {
      if (this.selectedQuantity < this.selectedVine.quantity) {
        this.selectedQuantity++;
      }
    },
    handleSetQuantityChange(vine, quantity) {
      //wyslac api ze zmiana ilosci
      if (navigator.onLine && this.test) {
        axios.put(process.env.VUE_APP_API_URL + '/product-order/' + vine.id, {quantity: quantity}, {}).then((response) => {
          this.loadVines();
        });
      } else {
        this.saveIntoIndexedDB('requests', {
          url: process.env.VUE_APP_API_URL + '/product-order/' + vine.id,
          data: {quantity: quantity}
        });
      }
      this.closeModal();
    },
    async loadAllVines(page = 1) {
      await axios.get(process.env.VUE_APP_API_URL + '/cart/products', {}).then((response) => {
        this.saveIntoIndexedDB('products', response.data.products)
        response.data.products.forEach(el => {
          this.getImageOrFetch(el.image, this.baseUrl + el.image)
        });
      });
    },

    async loadVines(page = 1, smallPriceExists = 0) {
      try {
        const options = {};
        options.small_price = smallPriceExists;
        if (this.selectedTypes.length > 0) {
          options.types = this.selectedTypes.join(',');
        }
        if (this.selectedCountries) {
          options.countries = this.selectedCountries.join(',')
        }
        this.vines = []
        if (this.$refs.mySwiper && this.$refs.mySwiper.swiper) {
          this.$refs.mySwiper.swiper.slideTo(0);
        }
        await axios.get(process.env.VUE_APP_API_URL + '/cart/products?' + new URLSearchParams(options), {
          headers: {
            'Authorization': `Bearer ${localStorage.token}`
          }
        }).then((response) => {
          this.vines = response.data.data;
        });
        for (let i = 0; i < this.vines.length; i++) {
          this.vines[i].image_special = this.images[this.vines[i].image]
        }
      } catch (error) {
        console.error(error);
      }
    },
    async loadCategories(catId = null) {
      try {
        await axios.get(process.env.VUE_APP_API_URL + '/cart/categories' + (catId ? ('?category_id=' + catId) : ''), {
          headers: {
            'Authorization': `Bearer ${localStorage.token}`
          }
        }).then((response) => {
          this.categories = response.data.categories;
          this.saveIntoIndexedDB('categories', response.data.categories);
          if (this.categories.length === 0) {
            this.showTypeModal = false;
            this.loadVines();
          }
        })
      } catch (error) {
        alert(error)
      }
    },

    handleShowCountryModal() {
      this.showCountryModal = true;
    },
    saveIntoIndexedDB(table, data) {
      let db;
      const openRequest = indexedDB.open('wineApp', 3);

      openRequest.onsuccess = function (e) {
        db = e.target.result;
        if (db.objectStoreNames.contains(table)) {
          const transaction = db.transaction([table], 'readwrite');
          const store = transaction.objectStore(table);
          if (table == 'requests') {
            store.add(data)
          } else {
            store.clear();
            for (const product of data) {
              store.put(product);
            }
          }
        }
      };
    },
    async openFromIndexedDB(table, options = null) {
      const openRequest = indexedDB.open('wineApp', 3);
      openRequest.onsuccess = function (e) {
        const db = e.target.result;

        if (db.objectStoreNames.contains(table)) {
          const transaction = db.transaction(table, 'readonly');
          const store = transaction.objectStore(table);

          // Pobranie wszystkich danych z magazynu obiektów
          const getRequest = store.getAll();

          getRequest.onsuccess = async function (event) {
            // event.target.result zawiera wszystkie obiekty z magazynu
            if (table === 'categories') {
              this.categories = event.target.result;
              if (this.categories.length == 0) {
                alert(this.$t('net_required'))
              }
            } else if (table === 'products') {
              this.vines = event.target.result;
              if (this.vines.length == 0) {
                alert(this.$t('net_required'))
              }
              if (options.types) {
                this.vines = this.vines.filter(vine => vine.type_id == options.types);
              }
              if (options.countries) {
                this.vines = this.vines.filter(vine => vine.country_id == options.countries);
              }
              if (options.small_price == 1) {
                this.vines = this.vines.filter(vine => vine.small_price !== null && vine.small_price != '0.00');
              }
              for (let i = 0; i < this.vines.length; i++) {
                if (!this.vines[i].image_special) {
                  this.vines[i].image_special = await Preferences.get({key: this.vines[i].image})
                }
              }
              if (this.selectedCountries.length == 0) {
                this.countries = [];
                // this.vines.forEach(el => {
                //   if (el.category_data[0].slug == 'Country') {
                //     const hasObject = this.countries.some(obj => obj.name === el.category_data[0].name);
                //     if (!hasObject) {
                //       this.countries.push(el.category_data[0]);
                //     }
                //   } else {
                //     const hasObject = this.countries.some(obj => obj.name === el.category_data[1].name);
                //     if (!hasObject) {
                //       this.countries.push(el.category_data[1]);
                //     }
                //   }
                // });
              }
            }
          }.bind(this);
        }
      }.bind(this);
    },
  },
  async mounted() {
    cart.showCart();
    const storage = new Storage();
    await storage.create();
    await storage.get('config').then(res => {
      if (res && res.data) {
        this.user = res.data;
        applyTheme(res.data);
      }
    });
    try {
      this.baseUrl = process.env.VUE_APP_API_URL.replace('/api', '');
      this.isLoading = true;
      const response = await axios.get(process.env.VUE_APP_API_URL + '/get-images');
      if (response.data.images) {
        for (const el of response.data.images) {
          this.images[el.key] = el.value
        }
      }
      this.isLoading = false;

      this.showTypeModal = true;
      await this.loadCategories();
      //  await this.loadAllVines();
      window.addEventListener('resize', this.checkOrientationAndScreenSize);
      await this.checkOrientationAndScreenSize();
    } catch (error) {
      console.log(error)
    }
  },
  computed: {
    isSmallPrice() {
      return this.vines.some(vine => vine.small_price > 0);
    }
  }
}
</script>

<style scoped>
#container {
  min-height: 100%;
  min-width: 100%;
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  margin: 0;
}

.container-blurred {
  backdrop-filter: blur(10px);
  min-height: 100%;
}

.logo-container {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

.fullscreen {
  --width: 100% !important;
  --height: 100% !important;
}

.swiper-image {
  border-radius: 8px;
  max-width: 90%;
  max-height: 52vh;
}

#container a {
  text-decoration: none;
}

.fixed-button {
  position: fixed;
  right: 20px; /* Odległość od prawej strony */
  bottom: 20px; /* Odległość od dolnej strony (możesz to dostosować według potrzeb) */
  z-index: 1000; /* Upewnij się, że przycisk będzie na wierzchu innych elementów */
}

.product-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-height: 16px;
  max-height: 40px;
  -webkit-box-orient: vertical;
}

</style>
